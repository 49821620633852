var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"pa-0":"","fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-model-table',{attrs:{"model-name":"Roles","model-title":"Roles","model-api":['models','User','Roles'],"model-key":"roleId","headers":_vm.headers,"dialog-width":(_vm.$vuetify.breakpoint.mdAndUp) ? '50vw' : '80vw',"searchable":""},scopedSlots:_vm._u([{key:"modelForm",fn:function(ref){
var data = ref.data;
var rules = ref.rules;
var isCreating = ref.isCreating;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[rules.require()],"label":"Role ID","disabled":!isCreating},model:{value:(data.roleId),callback:function ($$v) {_vm.$set(data, "roleId", $$v)},expression:"data.roleId"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Role Name"},model:{value:(data.roleName),callback:function ($$v) {_vm.$set(data, "roleName", $$v)},expression:"data.roleName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-table-field',{attrs:{"title":"Permissions","item-key":"permissionId","headers":_vm.permissionsHeaders,"hide-toolbar":"","show-create-pad":"","auto-item-action":""},scopedSlots:_vm._u([{key:"createPad",fn:function(ref){
var tmplData = ref.data;
var rules = ref.rules;
return [_c('v-container',{attrs:{"fluid":"","pa-0":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-master-field',{attrs:{"rules":[rules.require()],"custom-api":['models','User','Permissions','query'],"custom-api-text":"description","custom-api-value":"permissionId","label":"Permissions","customApiEagerLoad":"","show-code":""},model:{value:(tmplData.permissionId),callback:function ($$v) {_vm.$set(tmplData, "permissionId", $$v)},expression:"tmplData.permissionId"}})],1)],1)],1)]}},{key:"item.description",fn:function(props){return [_c('v-label-api',{attrs:{"api-url":['models','User','Permissions','find'],"api-key":props.item.permissionId,"api-field":"description"}})]}}],null,true),model:{value:(data.permissions),callback:function ($$v) {_vm.$set(data, "permissions", $$v)},expression:"data.permissions"}})],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }